import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { BaseWrapper, Button, Checkbox, H3, H5, ImageHandler, Input, MultiSelect, TOAST_TYPE, TextEditor, Textarea } from '@base'
import { categoryCustomisations, strings } from '@constants'
import { Devices, Spacings } from '@styles'
import { editCategory, getAllBrandApi, getCategoryById, uploadCategoryImages } from '@data'
import { useForceUpdate, useHeaderMeta } from '@hooks'
import { showLoader, showToast } from '@data/state/action'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

const PseudoWrapper = styled.div`
	position: sticky;
	bottom: 0;
	z-index: 1;
	background: ${({ theme }) => theme.palette.background.searchgrey};
	border-top: 1px solid ${({ theme }) => theme.palette.background.searchgrey};

	@media ${Devices.tablet} {
		margin: 0 -${Spacings.SPACING_4B};
	}
`
const Footer = styled.div`
	padding: ${Spacings.SPACING_3B} ${Spacings.SPACING_4B};
	background: ${({ theme }) => theme.palette.background.white};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	display: flex;
	flex-direction: column;
`
const PsuedoFullHeightWrapper = styled(BaseWrapper)`
	flex-grow: 1;
`
const InfoCreateWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: ${Spacings.SPACING_6B};
	@media ${Devices.tablet} {
		flex-wrap: nowrap;
	}
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	margin-bottom: ${Spacings.SPACING_3B};
	@media ${Devices.tablet} {
		flex-basis: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
		flex-grow: ${({ fullWidth }) => (fullWidth ? '1' : '0')};
	}
`

const FormBody = styled.div`
	display: flex;
	flex-direction: column;
`

const FormElementWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	// max-width: 25rem;

	margin-top: ${Spacings.SPACING_1B};

	& > div {
		width: 100%;
	}
`

const Heading = styled(H3)`
	padding: ${Spacings.SPACING_3B} 0 ${Spacings.SPACING_3B};
	color: ${({ theme }) => theme.palette.text.darkblack};
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.searchgrey};
	margin-bottom: ${Spacings.SPACING_2B};
	margin-top: ${({ noTopMargin }) => (noTopMargin ? 0 : Spacings.SPACING_2B)};

	@media ${Devices.tablet} {
		margin-top: 0;
	}
`

const Subheading = styled(H5)`
	margin-bottom: ${Spacings.SPACING_2B};
	margin-top: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.darkblack};
	@media ${Devices.tablet} {
		// margin-top: 0;
	}
`

const BiFieldWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	column-gap: ${Spacings.SPACING_3B};
	& > div {
		width: 100%;
	}
`
const CheckBoxWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: ${Spacings.SPACING_3B};
	&: nth-child(1) {
		height: 100%;
		width: 100%;
	}
	& > div {
		width: 100%;
	}
`

const ImageWrapper = styled.div`
	width: 100%;
	height: 100%;
	max-width: 22rem;
	@media ${Devices.mobile} {
		max-width: 32rem;
	}
	@media ${Devices.tablet} {
		max-width: 23rem;
	}
	@media ${Devices.laptop} {
		max-width: 22rem;
	}
	@media ${Devices.desktop} {
		max-width: 33rem;
	}
`

const customisationsType = Object.entries(categoryCustomisations).map(([key, value]) => ({ key, label: value }))

const deriveState = (data) => {
	const _state = {
		advantage: {
			id: 'advantage',
			label: strings('advantage'),
			disabled: false,
			placeholder: strings(['enter', 'advantage', '...']),
			value: '',
			required: false,
		},
		application: {
			id: 'application',
			label: strings('application'),
			disabled: false,
			placeholder: strings(['enter', 'applications', '...']),
			value: '',
			required: false,
		},
		description1: {
			id: 'first-description',
			label: strings(['description', 'box', '1']),
			disabled: false,
			placeholder: strings(['enter', 'description', '...']),
			value: '',
			required: false,
		},
		description2: {
			id: 'second-description',
			label: strings(['description', 'box', '2']),
			disabled: false,
			placeholder: strings(['enter', 'description', '...']),
			value: '',
			required: false,
		},
		thickness: {
			id: 'thickness',
			label: strings('thickness'),
			disabled: false,
			placeholder: strings(['enter', 'thickness']),
			value: '',
			required: false,
		},
		width: {
			id: 'width',
			label: strings('width'),
			disabled: false,
			placeholder: strings(['enter', 'width']),
			value: '',
			required: false,
		},
		height: {
			id: 'height',
			label: strings('height'),
			disabled: false,
			placeholder: strings(['enter', 'height']),
			value: '',
			required: false,
		},
		diameter: {
			id: 'diameter',
			label: strings('diameter'),
			placeholder: strings(['enter', 'diameter']),
			value: '',
			disabled: false,
			required: false,
		},
		length: {
			id: 'length',
			label: strings('length'),
			placeholder: strings(['enter', 'length']),
			value: '',
			disabled: false,
			required: false,
		},
		grade: {
			id: 'grade',
			label: strings('grade'),
			placeholder: strings(['enter', 'grade']),
			value: '',
			disabled: false,
			required: false,
		},
		brands: {
			id: 'brands',
			label: strings('brands'),
			placeholder: strings('enter', 'brands'),
			value: null,
			primaryKey: 'brand_id',
			displayKey: 'brand_name',
			disabled: false,
			required: false,
		},
		customisation_available: {
			id: 'customisation-available',
			value: false,
			disabled: false,
			required: false,
		},
		customizations: {
			id: 'customisations',
			label: strings('customisations'),
			placeholder: strings(['select', 'customisations']),
			value: null,
			data: customisationsType,
			primaryKey: 'key',
			displayKey: 'label',
			disabled: false,
			required: false,
		},
		og_title: {
			id: 'meta-title',
			label: strings('meta_title'),
			placeholder: strings('enter', 'meta_title'),
			value: '',
			disabled: false,
			required: false,
		},
		og_description: {
			id: 'meta-description',
			label: strings('meta_description'),
			placeholder: strings('enter', 'meta_description'),
			value: '',
			disabled: false,
			required: false,
		},
		og_image: {
			id: 'og-image',
			label: strings('og_image'),
			placeholder: strings(['enter', 'og_image']),
			value: '',
			disabled: false,
			required: false,
		},
		og_url: {
			id: 'og-url',
			label: strings('og_url'),
			placeholder: strings('enter', 'og_url'),
			value: '',
			disabled: false,
			required: false,
		},
		og_locale: {
			id: 'og-locale',
			label: strings('og_locale'),
			placeholder: strings('select', 'og_locale'),
			value: '',
			disabled: false,
			required: false,
		},
	}

	if (data) {
		_state.advantage.value = data?.advantage
		_state.application.value = data?.application
		_state.description1.value = data?.description1
		_state.description2.value = data?.description2
		_state.thickness.value = data?.specifications?.thickness
		_state.width.value = data?.specifications?.width
		_state.height.value = data?.specifications?.height
		_state.diameter.value = data?.specifications?.diameter
		_state.length.value = data?.specifications?.length
		_state.grade.value = data?.specifications?.grade
		// _state.brands.value = data?.specifications?.brands
		_state.customisation_available.value = data?.specifications?.customisation_available
		_state.customizations.value = customisationsType.filter((item) => data?.specifications?.customizations.includes(item.key))
		if (data?.open_graph_tags) {
			_state.og_title.value = data?.open_graph_tags?.og_title
			_state.og_description.value = data?.open_graph_tags?.og_description
			_state.og_image.value = data?.open_graph_tags?.og_image
			_state.og_url.value = data?.open_graph_tags?.og_url
			_state.og_locale = data?.open_graph_tags?.og_locale
		}
	}

	return _state
}

const CategoryInfo = () => {
	const [formState, setFormState] = useState(deriveState())
	const [brandList, setBrandList] = useState([])
	const [categoryImages, setCategoryImages] = useState([])
	const { categoryId } = useParams()
	const [categoryData, setCategoryData] = useState({})
	const dispatch = useDispatch()
	const [forceUpdate, update] = useForceUpdate()

	useHeaderMeta({
		title: strings('edit', 'category'),
	})
	useEffect(() => {
		dispatch(showLoader(true))
		getCategoryById(categoryId)
			.then((res) => {
				setFormState(deriveState(res))
				setCategoryData(res)
				setCategoryImages(res?.images)
			})
			.catch((e) => {
				console.log(e)
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}, [forceUpdate])

	useEffect(() => {
		categoryData &&
			getAllBrandApi()
				.then((res) => {
					setBrandList(res.data)
					setFormState((_s) => ({
						..._s,
						['brands']: {
							..._s['brands'],
							value: res.data.filter((item) => categoryData?.specifications?.brands?.includes(item.brand_name)),
						},
					}))
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {})
	}, [categoryData])

	const onChangeHandler = (key) => (value) => {
		setFormState((_state) => ({
			..._state,
			[key]: {
				..._state[key],
				value,
			},
		}))
	}

	const formStateToCategory = () => {
		let _state = {
			advantage: formState.advantage?.value,
			application: formState.application?.value,
			description1: formState.description1?.value,
			description2: formState.description2?.value,
			specifications: {
				brands: formState.brands?.value?.map((item) => item.brand_name),
				customisation_available: formState.customisation_available?.value,

				customizations: formState.customizations?.value?.map((item) => {
					return item.key
				}),
				diameter: formState.diameter?.value,
				grade: formState.grade?.value,
				height: formState.height?.value,
				length: formState.length?.value,
				thickness: formState.thickness?.value,
				width: formState.width?.value,
			},
			images: categoryImages,
			open_graph_tags: {
				og_title: formState?.og_title?.value,
				og_description: formState?.og_description?.value,
				og_image: formState?.og_image?.value,
				og_url: formState?.og_url?.value,
				og_locale: 'en-US',
				og_type: 'PRODUCT',
				og_site_name: 'Metalbook',
			},
		}
		return _state
	}

	const addInfoHandler = () => {
		dispatch(showLoader(true))
		const _categoryData = formStateToCategory()
		editCategory({ ...categoryData, ..._categoryData }, categoryId)
			.then(() => {
				dispatch(showToast(true, strings('category', 'updated'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch(() => {
				dispatch(showToast(true, strings(['category', 'not', 'updated']), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}
	const uploadPhotoHandler = (files) => {
		const getPath = Array.from(files).map((f) => f)
		dispatch(showLoader(true))
		uploadCategoryImages(categoryId, getPath)
			.then((response) => {
				dispatch(showToast(true, strings('uploaded_the_file_successfully'), { type: TOAST_TYPE.SUCCESS }))
				setCategoryImages(response)
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, `${strings('document_upload_failed')} ${err}`, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}
	return (
		<Wrapper>
			<PsuedoFullHeightWrapper>
				<Heading>{strings(['enter', 'additional', 'details'])}</Heading>
				<InfoCreateWrapper>
					<Section>
						<FormBody>
							<FormElementWrapper>
								<BiFieldWrapper>
									<Input
										id={formState.thickness.id}
										type='text'
										label={formState.thickness.label}
										placeholder={formState.thickness.placeholder}
										disabled={formState.thickness.disabled}
										value={formState.thickness.value}
										required={formState.thickness.required}
										onChange={onChangeHandler('thickness')}
									/>
									<Input
										id={formState.width.id}
										type='text'
										label={formState.width.label}
										placeholder={formState.width.placeholder}
										disabled={formState.width.disabled}
										value={formState.width.value}
										required={formState.width.required}
										onChange={onChangeHandler('width')}
									/>
								</BiFieldWrapper>
							</FormElementWrapper>
							<FormElementWrapper>
								<BiFieldWrapper>
									<Input
										id={formState.height.id}
										type='text'
										label={formState.height.label}
										placeholder={formState.height.placeholder}
										disabled={formState.height.disabled}
										value={formState.height.value}
										required={formState.height.required}
										onChange={onChangeHandler('height')}
									/>
									<Input
										id={formState.diameter.id}
										type='text'
										label={formState.diameter.label}
										placeholder={formState.diameter.placeholder}
										disabled={formState.diameter.disabled}
										value={formState.diameter.value}
										required={formState.diameter.required}
										onChange={onChangeHandler('diameter')}
									/>
								</BiFieldWrapper>
							</FormElementWrapper>
							<FormElementWrapper>
								<BiFieldWrapper>
									<Input
										id={formState.length.id}
										type='text'
										label={formState.length.label}
										placeholder={formState.length.placeholder}
										disabled={formState.length.disabled}
										value={formState.length.value}
										required={formState.length.required}
										onChange={onChangeHandler('length')}
									/>
									<Input
										id={formState.grade.id}
										type='text'
										label={formState.grade.label}
										placeholder={formState.grade.placeholder}
										disabled={formState.grade.disabled}
										value={formState.grade.value}
										required={formState.grade.required}
										onChange={onChangeHandler('grade')}
									/>
								</BiFieldWrapper>
							</FormElementWrapper>
							<FormElementWrapper>
								<MultiSelect
									id={formState.brands.id}
									label={formState.brands.label}
									placeholder={formState.brands?.placeholder}
									disabled={formState.brands.disabled}
									value={formState.brands?.value || []}
									required={formState.brands.required}
									primaryKey={formState.brands.primaryKey}
									displayKey={formState.brands.displayKey}
									data={brandList}
									onChange={onChangeHandler('brands')}
								/>
							</FormElementWrapper>

							<FormElementWrapper>
								<Subheading>{strings('customisations')}</Subheading>
								<CheckBoxWrapper>
									<Checkbox
										id='logistics-required-checkbox'
										// size={1.125}
										checked={formState.customisation_available.value}
										value={formState.customisation_available.value}
										onChange={(e) => {
											onChangeHandler('customizations')([])
											onChangeHandler('customisation_available')(e.target.checked)
										}}
									/>
									<MultiSelect
										id={formState.customizations.id}
										data={formState.customizations.data}
										placeholder={formState.customizations.placeholder}
										primaryKey={formState.customizations.primaryKey}
										displayKey={formState.customizations.displayKey}
										value={formState.customizations?.value || []}
										disabled={!formState.customisation_available.value}
										required={formState.customizations.required}
										onChange={onChangeHandler('customizations')}
									/>
								</CheckBoxWrapper>
							</FormElementWrapper>

							<FormElementWrapper>
								<Input
									id={formState?.og_image?.id}
									label={formState?.og_image?.label}
									placeholder={formState?.og_image?.placeholder}
									value={formState?.og_image?.value}
									pattern='https?://.*'
									errorMap={{
										patternMismatch: strings('enter_valid_url_link'),
									}}
									disabled={formState?.og_image?.disabled}
									required={formState?.og_image?.required}
									onChange={onChangeHandler('og_image')}
								/>
							</FormElementWrapper>
							<FormElementWrapper>
								<Input
									id={formState?.og_url?.id}
									label={formState?.og_url?.label}
									placeholder={formState?.og_url?.placeholder}
									value={formState?.og_url?.value}
									disabled={formState?.og_url?.disabled}
									required={formState?.og_url?.required}
									onChange={onChangeHandler('og_url')}
								/>
							</FormElementWrapper>
							<FormElementWrapper>
								<Subheading>{strings('applications')}</Subheading>
								<TextEditor
									placeholder={formState.application.placeholder}
									value={formState?.application?.value}
									onChange={onChangeHandler('application')}
									height='auto'
								/>
							</FormElementWrapper>
							<FormElementWrapper>
								<ImageWrapper>
									<ImageHandler images={categoryImages} handler={uploadPhotoHandler} />
								</ImageWrapper>
							</FormElementWrapper>
						</FormBody>
					</Section>
					<Section>
						<FormBody>
							<FormElementWrapper>
								<Input
									id={formState?.og_title?.id}
									label={formState?.og_title?.label}
									placeholder={formState?.og_title?.placeholder}
									value={formState?.og_title?.value}
									required={formState?.og_title?.required}
									disabled={formState?.og_title?.disabled}
									onChange={onChangeHandler('og_title')}
								/>
							</FormElementWrapper>
							<FormElementWrapper>
								<Textarea
									id={formState?.og_description?.id}
									label={formState?.og_description?.label}
									placeholder={formState?.og_description?.placeholder}
									value={formState?.og_description?.value}
									onChange={onChangeHandler('og_description')}
								/>
							</FormElementWrapper>
							<FormElementWrapper>
								<Subheading>{strings(['description', '1'])}</Subheading>
								<TextEditor
									placeholder={formState.description1.placeholder}
									value={formState?.description1?.value}
									onChange={onChangeHandler('description1')}
									height='auto'
								/>
							</FormElementWrapper>
							<FormElementWrapper>
								<Subheading>{strings(['description', '2'])}</Subheading>
								<TextEditor
									placeholder={formState.description2.placeholder}
									value={formState?.description2?.value}
									onChange={onChangeHandler('description2')}
									height='auto'
								/>
							</FormElementWrapper>
							<FormElementWrapper>
								<Subheading>{strings('advantage')}</Subheading>
								<TextEditor
									placeholder={formState.advantage.placeholder}
									value={formState?.advantage?.value}
									onChange={onChangeHandler('advantage')}
									height='auto'
								/>
							</FormElementWrapper>
						</FormBody>
					</Section>
				</InfoCreateWrapper>
				<Section>
					<FormBody></FormBody>
				</Section>
			</PsuedoFullHeightWrapper>
			<PseudoWrapper>
				<Footer>
					<Button small margin='0 0 0 auto' onClick={addInfoHandler}>
						{strings('submit')}
					</Button>
				</Footer>
			</PseudoWrapper>
		</Wrapper>
	)
}

export default CategoryInfo
