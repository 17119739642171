import { Button, Calendar, DrawerBodyWrapper, DrawerFooterWrapper, H4, Input, RadioButton, Select, TOAST_TYPE, UploadButton } from '@base'
import { LOGISTICS_HANDLER_TYPES, strings } from '@constants'
import { convertToOrder, getExpectedTransitTime, updateCostMetrics, uploadPunchOrderFile } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer, toggleFileViewer } from '@data/state/action/root'
import { InputWrapper } from '@pages/Enquiry/CreateEnquiry/styles'
import { Spacings, Text } from '@styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Label } from '../EditSupplierQuotes/styles'
import { TextValue } from '@pages/ProfileV2/Profile/styles'
import { displayAmount } from '@utils'
import { useNavigate } from 'react-router'
import { paths } from '@routes'

const BiFieldWrapper = styled.div`
	margin-bottom: ${Spacings.SPACING_2B};
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: ${Spacings.SPACING_2B};
`

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: ${({ isRadio }) => (isRadio ? Spacings.SPACING_16B : Spacings.SPACING_2B)};
`

const SectionItem = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	column-gap: ${Spacings.SPACING_3B};
	height: fit-content;
`

const ValueWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	column-gap: ${Spacings.SPACING_3B};
	margin-bottom: ${Spacings.SPACING_2};
`

const SectionHeading = styled.div`
	${({ isRequired }) => {
		if (isRequired) {
			return css`
				:after {
					font-size: ${Text.EXTRA_SMALL};
					content: ' (required *)';
					color: red;
					position: relative;
					top: -13%;
				}
			`
		}
	}}
`

const logisticsHandlerTypes = Object.entries(LOGISTICS_HANDLER_TYPES).map(([key, value]) => ({ key, label: value }))

const deriveFormState = (order) => {
	const _state = {
		cm_per_mt: {
			id: 'cost-per-mt',
			label: strings(['cm', 'per', 'mt']),
			placeholder: strings(['enter', 'cm', 'per', 'mt']),
			value: '',
			dirty: false,
			required: true,
			disabled: false,
		},
		marketing_expense: {
			id: 'marketing-expense',
			label: strings(['marketing_expense', 'per', 'mt']),
			placeholder: strings(['enter', 'marketing_expense', 'per', 'mt']),
			value: '',
			dirty: false,
			required: true,
			disabled: false,
		},
		net_margin_per_mt: {
			id: 'net-margin-per-mt',
			label: strings('net_margin'),
			placeholder: strings(['enter', 'net_margin']),
			value: '',
			dirty: false,
			required: false,
			disabled: false,
		},
		freight_per_mt: {
			id: 'freight-per-mt',
			label: strings(['freight', 'per', 'mt']),
			placeholder: strings(['enter', 'freight', 'per', 'mt']),
			value: '',
			dirty: false,
			required: false,
			disabled: false,
		},
		logistics_handler: {
			id: 'logistic-handler',
			label: strings('logistics', 'handler'),
			placeholder: strings(['enter', 'logistics', 'handler']),
			value: '',
			dirty: false,
			data: logisticsHandlerTypes,
			primaryKey: 'key',
			displayKey: 'label',
			required: false,
			disabled: false,
		},
		expected_delivery_date: {
			id: 'expected-delivery-date',
			label: strings(['expected', 'delivery', 'date']),
			value: new Date(),
			dirty: false,
			disabled: false,
			required: false,
		},
		make_to_order: {
			id: 'make-to-order',
			label: strings(['make', 'to', 'order']),
			dirty: false,
			value: false,
		},
		management_approval: {
			value: '',
			required: false,
			dirty: false,
			disabled: false,
		},
		po: {
			value: '',
			required: false,
			dirty: false,
			disabled: false,
		},
	}

	if (order?.cost_metrics) {
		_state.cm_per_mt.value = order?.cost_metrics?.cm_per_mt
		_state.cm_per_mt.dirty = true

		_state.marketing_expense.value = order?.cost_metrics?.marketing_expense
		_state.cm_per_mt.dirty = true

		_state.net_margin_per_mt.value = order?.cost_metrics?.net_margin_per_mt
		_state.net_margin_per_mt.dirty = true

		_state.freight_per_mt.value = order?.cost_metrics?.freight_per_mt
		_state.freight_per_mt.dirty = true

		_state.logistics_handler.value = order?.cost_metrics?.logistics_handler
		_state.logistics_handler.dirty = true

		_state.expected_delivery_date.value = order?.cost_metrics?.expected_delivery_date
		_state.expected_delivery_date.dirty = true

		_state.make_to_order.value = order?.cost_metrics?.make_to_order
		_state.make_to_order.dirty = true

		if (order?.cost_metrics?.management_approval) {
			_state.management_approval.value = order?.cost_metrics?.management_approval
			_state.management_approval.dirty = true
		}

		_state.po.value = order?.cost_metrics?.po
		_state.po.dirty = true
	}

	return _state
}

const AddCostMetrics = ({
	enquiry,
	edit = false,
	order = null,
	orderDate = null,
	userId = null,
	enquiryId = null,
	setSuccessMessage = null,
	update,
	pastDelays = 0,
}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [formState, setFormState] = useState(deriveFormState())
	const [totalDays, setTotalDays] = useState(parseInt(enquiry?.payment_tenure ?? 0) + parseInt(pastDelays ?? 0))

	useEffect(() => {
		if (order) {
			setFormState(deriveFormState(order))
			setTotalDays(order?.cost_metrics?.credit_days)
		}
	}, [order])

	useEffect(() => {
		if (enquiry) {
			dispatch(showLoader(true))
			// !order &&
			getExpectedTransitTime(enquiry?.enquiry_id)
				.then((response) => {
					// setTransitTime(response)
					setTotalDays(totalDays + response)
				})
				.catch((error) => {
					console.log(error)
				})
				.finally(() => {
					dispatch(showLoader(false))
				})
		}
	}, [order])

	const updateState = (field) => (value) => {
		setFormState((_s) => ({ ..._s, [field]: { ..._s[field], value: value, dirty: true } }))

		if (field === 'cm_per_mt' || field === 'marketing_expense') {
			if (field === 'cm_per_mt' && formState?.marketing_expense?.value !== '') {
				updateState('net_margin_per_mt')(value - formState?.marketing_expense?.value)
			} else if (field === 'marketing_expense' && formState?.cm_per_mt?.value !== '') {
				updateState('net_margin_per_mt')(formState?.cm_per_mt?.value - value)
			} else {
				updateState('net_margin_per_mt')(0)
			}
			if (value === '') {
				updateState('net_margin_per_mt')(0)
			}
		}
	}
	let [totalQuantity, totalPoAmount] = useMemo(() => {
		let _totalQuantity, _totalPoAmount
		if (order?.order_id) {
			_totalQuantity = order?.buyer_list?.[0]?.order_items?.reduce((acc, item) => acc + item?.ordered_weight, 0) / 1000
			_totalPoAmount = order?.buyer_list?.[0]?.order_items?.reduce((acc, item) => acc + item?.price_per_kg * item?.ordered_weight, 0)
		} else {
			_totalQuantity = enquiry?.final_quote_buyer?.quote_per_product?.reduce((acc, item) => acc + item?.serviceable_weight, 0) / 1000
			_totalPoAmount = enquiry?.final_quote_buyer?.quote_per_product?.reduce(
				(acc, item) => acc + (item?.for_price + item?.ex_price) * item?.serviceable_weight,
				0
			)
		}
		return [_totalQuantity, _totalPoAmount]
	}, [enquiry, order])

	const viewFileHandler = (type) => (file) => {
		if (!file) {
			return
		}
		dispatch(toggleFileViewer(true, { files: file }))
	}

	const uploadDocumentHandler = (type) => (file) => {
		if (!file) {
			return
		}

		dispatch(showLoader(true))
		uploadPunchOrderFile(file)
			.then((response) => {
				updateState(type)(response)
				dispatch(showToast(true, strings('uploaded_the_file_successfully'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				console.log(error)
				dispatch(showToast(true, strings('document_upload_failed'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const updateHandler = () => {
		dispatch(showLoader(true))
		updateCostMetrics(order?.order_id, {
			...order?.cost_metrics,
			cm_per_mt: formState?.cm_per_mt?.value,
			expected_delivery_date: new Date(formState?.expected_delivery_date?.value).getTime(),
			freight_per_mt: formState?.freight_per_mt?.value,
			logistics_handler: formState?.logistics_handler?.value,
			make_to_order: formState?.make_to_order?.value,
			management_approval: formState?.management_approval?.value,
			marketing_expense: formState?.marketing_expense?.value,
			net_margin_per_mt: formState?.net_margin_per_mt?.value,
			po: formState?.po?.value,
			credit_days: totalDays,
		})
			.then((response) => {
				dispatch(showToast(true, strings('msg_cost_metrics_submitted'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				dispatch(showToast(true, strings('msg_cost_metrics_submission_failed'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(toggleDrawer(false))
				dispatch(showLoader(false))
			})
	}

	const submitHandler = () => {
		let orderId
		dispatch(showLoader(true))
		convertToOrder(enquiryId, userId, orderDate, {
			cm_per_mt: formState?.cm_per_mt?.value,
			expected_delivery_date: new Date(formState?.expected_delivery_date?.value).getTime(),
			freight_per_mt: formState?.freight_per_mt?.value,
			logistics_handler: formState?.logistics_handler?.value,
			make_to_order: formState?.make_to_order?.value,
			management_approval: formState?.management_approval?.value,
			marketing_expense: formState?.marketing_expense?.value,
			net_margin_per_mt: formState?.net_margin_per_mt?.value,
			po: formState?.po?.value,
			credit_days: totalDays,
		})
			.then((res) => {
				dispatch(showToast(true, strings('msg_order_created'), { type: TOAST_TYPE.SUCCESS }))
				setSuccessMessage(res?.data?.order_id)
				orderId = res?.data?.order_id
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, err?.response?.data?.message, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(toggleDrawer(false))
				orderId && navigate(paths.admin_manage_enquiry_order_edit(orderId))
				dispatch(showLoader(false))
			})
	}

	const isApproved = useMemo(() => {
		// management approval is required when isApproved is true
		// let expected_transit_time = order?.order_id ? transitTime || enquiry?.expected_transit_time : 0

		if (!enquiry?.payment_tenure || !totalPoAmount) {
			return false
		}

		// let days =
		// 	(paymentTenure ? parseFloat(paymentTenure) : 0) +
		// 	(expected_transit_time ? parseInt(expected_transit_time) : 0) +
		// 	(pastDelays ? parseInt(pastDelays) : 0)
		let days = totalDays
		let n_margin = formState?.cm_per_mt?.value ?? 0 - formState?.marketing_expense?.value ?? 0
		let margin_threshold_perc_daywise = ((2 / 100) * days) / 30
		let amt = ((totalPoAmount / 1.18) * margin_threshold_perc_daywise) / totalQuantity
		return amt > n_margin
	}, [enquiry?.payment_tenure, formState?.cm_per_mt?.value, formState?.marketing_expense?.value, totalPoAmount, totalQuantity, totalDays])

	const isDisabled = useMemo(() => {
		return (
			formState?.cm_per_mt?.value === '' ||
			formState?.freight_per_mt?.value === '' ||
			!formState?.logistics_handler?.dirty ||
			!formState?.expected_delivery_date?.value ||
			!formState?.po?.value ||
			formState?.marketing_expense?.value === '' ||
			(isApproved && !formState?.management_approval?.value)
		)
	}, [formState, isApproved])
	return (
		<>
			<DrawerBodyWrapper>
				<BiFieldWrapper>
					<Input
						id='cm-per-mt-input'
						type='number'
						label={formState.cm_per_mt?.label}
						placeholder={formState?.cm_per_mt?.placeholder}
						value={formState?.cm_per_mt?.value}
						onChange={updateState('cm_per_mt')}
					/>
					<Input
						id='marketing-expense'
						type='number'
						label={formState?.marketing_expense?.label}
						placeholder={formState?.marketing_expense?.placeholder}
						value={formState?.marketing_expense?.value}
						onChange={updateState('marketing_expense')}
					/>
				</BiFieldWrapper>
				<BiFieldWrapper>
					<Input
						id={formState?.net_margin_per_mt?.id}
						type='number'
						label={formState?.net_margin_per_mt?.label}
						placeholder={formState?.net_margin_per_mt?.placeholder}
						value={formState?.net_margin_per_mt?.value}
						onChange={updateState('net_margin_per_mt')}
						disabled={true}
					/>
					<Input
						id='freight-per-mt'
						type='number'
						label={formState?.freight_per_mt?.label}
						placeholder={formState?.freight_per_mt?.placeholder}
						value={formState?.freight_per_mt?.value}
						onChange={updateState('freight_per_mt')}
					/>
				</BiFieldWrapper>
				<BiFieldWrapper>
					<Select
						id={formState?.logistics_handler?.id}
						label={formState?.logistics_handler?.label}
						placeholder={formState?.logistics_handler?.placeholder}
						data={logisticsHandlerTypes}
						value={logisticsHandlerTypes?.find((item) => formState?.logistics_handler?.value === item.key) ?? {}}
						displayKey={formState?.logistics_handler?.displayKey}
						primaryKey={formState?.logistics_handler?.primaryKey}
						onChange={(value) => updateState('logistics_handler')(value.key)}
					/>
					<Calendar
						id={formState?.expected_delivery_date?.id}
						label={formState?.expected_delivery_date?.label}
						value={formState?.expected_delivery_date?.value}
						onChange={updateState('expected_delivery_date')}
					/>
				</BiFieldWrapper>
				<BiFieldWrapper>
					<ValueWrapper>
						<SectionHeading>{strings(['payment_tenure', ': '])}</SectionHeading>
						<TextValue>{totalDays ?? 0}</TextValue>
					</ValueWrapper>
					<ValueWrapper>
						<SectionHeading>{strings(['po', 'amount', ': '])}</SectionHeading>
						<TextValue>{displayAmount(totalPoAmount) ?? 0}</TextValue>
					</ValueWrapper>
				</BiFieldWrapper>
				<InputWrapper>
					<SectionItem>
						<SectionHeading isRequired={isApproved}>{strings('management', 'approval')}</SectionHeading>
						<ButtonsWrapper>
							<Button
								type='secondary'
								variant='destructive'
								xs
								disabled={!formState?.management_approval?.value}
								onClick={() => {
									viewFileHandler('management_approval')(formState?.management_approval?.value)
								}}
							>
								{strings('view')}
							</Button>
							<UploadButton
								label={strings(['upload'])}
								small
								type='primary'
								disabled={false}
								onChange={(file) => uploadDocumentHandler('management_approval')(file)}
								xs
							/>
						</ButtonsWrapper>
					</SectionItem>
				</InputWrapper>
				<InputWrapper>
					<SectionItem>
						<SectionHeading isRequired={!formState?.po?.value}>{strings('customer', 'po')}</SectionHeading>
						<ButtonsWrapper>
							<Button
								type='secondary'
								xs
								variant='destructive'
								disabled={!formState?.po?.value}
								onClick={() => viewFileHandler('po')(formState?.po?.value)}
							>
								{strings('view')}
							</Button>
							<UploadButton
								label={strings(['upload'])}
								small
								type='primary'
								disabled={false}
								onChange={(file) => uploadDocumentHandler('po')(file)}
								xs
							/>
						</ButtonsWrapper>
					</SectionItem>
				</InputWrapper>
				<InputWrapper>
					<SectionItem>
						<H4>{strings('make_to_order')}</H4>
						<ButtonsWrapper isRadio>
							<ButtonsWrapper>
								<RadioButton
									id='true-radio'
									size='1.125'
									type='bool'
									checked={formState?.make_to_order?.value === true}
									value={true}
									onChange={() => updateState('make_to_order')(true)}
								/>
								<Label>{strings('YES')}</Label>
							</ButtonsWrapper>
							<ButtonsWrapper>
								<RadioButton
									id='false-radio'
									size='1.125'
									type='bool'
									checked={formState?.make_to_order?.value === false}
									value={false}
									onChange={() => updateState('make_to_order')(false)}
								/>
								<Label>{strings('NO')}</Label>
							</ButtonsWrapper>
						</ButtonsWrapper>
					</SectionItem>
				</InputWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				{edit ? (
					<Button small onClick={updateHandler} disabled={isDisabled}>
						{strings('update')}
					</Button>
				) : (
					<Button small onClick={submitHandler} disabled={isDisabled}>
						{strings('convert_to_order')}
					</Button>
				)}
			</DrawerFooterWrapper>
		</>
	)
}

export default AddCostMetrics
