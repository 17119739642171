import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { strings } from '@constants'
import { DuoWrapper, FooterWrapper, PsuedoFullHeightWrapper, Section, Wrapper } from '../styles'
import { Button, Input, PageLevelTableWrapper, Table, TextEditor, TOAST_TYPE } from '@base'
import { getGlossaryBySlug } from '@data/conn/get/glossary'
import { showLoader, showToast } from '@data/state/action'
import { createGlossary } from '@data/conn/post/glossary'
import { useForceUpdate, useHeaderMeta } from '@hooks'
import { editGlossary } from '@data/conn/put/glossary'
import { adminGlossary } from '@utils'
import { GridListWrapper } from '@pages/ProfileV2/Profile/styles'

const TableWrapper = styled(PageLevelTableWrapper)`
	min-height: 300px;
`

const deriveFormState = (data) => {
	let _state = {
		keyword: {
			id: 'keyword',
			label: strings('keyword'),
			placeholder: strings('enter', 'keyword'),
			value: '',
			required: true,
		},
		slug: {
			id: 'slug',
			label: strings('slug'),
			placeholder: strings('enter', 'slug'),
			value: '',
			required: true,
		},
		description: {
			id: 'description',
			label: strings('description'),
			placeholder: strings('enter', 'description'),
			value: '',
			required: true,
		},
	}

	if (data) {
		_state.keyword.value = data?.keyword
		_state.slug.value = data?.slug
		_state.description.value = data?.description
	}

	return _state
}

const AddGlossary = () => {
	useHeaderMeta(adminGlossary)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const loggedInUser = useSelector((state) => state.getIn(['user', 'user']))
	const [forceUpdate, update] = useForceUpdate()
	const [glossary, setGlossary] = useState({})
	const [relatedContent, setRelatedContent] = useState([])

	const [formState, setFormState] = useState(deriveFormState())
	const { glossarySlug } = useParams()

	useEffect(() => {
		if (glossarySlug) {
			dispatch(showLoader(true))
			getGlossaryBySlug(glossarySlug)
				.then((response) => {
					setFormState(deriveFormState(response))
					setGlossary(response)
					setRelatedContent(response?.content_summary || [])
				})
				.catch((error) => {
					console.log(error)
				})
				.finally(() => {
					dispatch(showLoader(false))
				})
		}
	}, [glossarySlug, forceUpdate])

	const updateState = (key) => (value) => {
		if (key === 'keyword') {
			value = value.replace(/^\s+/g, '').toLowerCase()
		}
		setFormState((_state) => ({ ..._state, [key]: { ..._state[key], value } }))
		if (key === 'keyword') {
			updateState('slug')(
				value
					.trim()
					.toLowerCase()
					.replace(/\s+/g, ' ')
					.replace(/[^a-z0-9\s]/g, '')
					.replace(/\s+/g, '-')
					.replace(/-+/g, '-')
			)
		}
	}

	const columns = useMemo(() => {
		return [
			{
				label: strings('related', 'blogs'),
				key: (item) => {
					return item?.title ? item?.title : '-'
				},
				mobile: true,
			},
		]
	}, [])

	const formStateToData = (edit) => {
		let _data = {
			keyword: formState.keyword.value,
			slug: formState.slug.value,
			description: formState.description.value,
		}
		if (edit) {
			_data = { ..._data, updated_by: loggedInUser.user_id, updated_at: new Date().getTime() }
		} else {
			_data = { ..._data, created_by: loggedInUser.user_id, created_at: new Date().getTime() }
		}
		return _data
	}

	const addGlossaryHandler = () => {
		dispatch(showLoader(true))
		const _glossary = formStateToData()
		createGlossary(_glossary)
			.then((response) => {
				dispatch(showToast(true, strings('msg_glossary_added_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				let msg = error?.message || strings('msg_glossary_added_failed')
				dispatch(showToast(true, msg, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				navigate(-1)
				dispatch(showLoader(false))
			})
	}

	const editGlossaryHandler = () => {
		dispatch(showLoader(true))
		const _data = formStateToData(true)
		editGlossary({ ...glossary, ..._data })
			.then((response) => {
				dispatch(showToast(true, strings('msg_edit_glossary_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				let error_msg = error?.message || strings('msg_edit_glossary_failure')
				dispatch(showToast(true, error_msg, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}

	const disabled = useMemo(() => {
		return Object.values(formState).some((d) => d.required && !d.value) || formState.description?.value?.length < 20
	}, [formState])

	const rowClickHandler = (row) => {
		let url = 'https://www.metalbook.co.in/blogs/'
		if (
			window.location.href.includes('lazy.metalbook.app') ||
			window.location.href.includes('dev.metalbook.app') ||
			process.env.REACT_APP_SERVER === 'develop'
		) {
			url = 'https://develop.d2zn1oavqk7hpv.amplifyapp.com/blogs/'
		}
		if (url) {
			window.open(url + row.slug, '_blank')
		}
	}

	return (
		<Wrapper>
			<PsuedoFullHeightWrapper>
				<DuoWrapper>
					<Section>
						<Input
							id={formState.keyword.id}
							label={formState.keyword.label}
							placeholder={formState.keyword.placeholder}
							value={formState.keyword.value}
							onChange={updateState('keyword')}
							required={formState.keyword.required}
						/>
						<Input
							id={formState.slug.id}
							label={formState.slug.label}
							placeholder={formState.slug.placeholder}
							value={formState.slug.value}
							onChange={updateState('slug')}
							required={formState.slug.required}
							pattern='^[a-z0-9]+(?:-[a-z0-9]+)*$'
							errorMap={{
								patternMismatch: strings('enter_valid_slug'),
							}}
						/>
						{glossarySlug && relatedContent?.length > 0 && (
							<GridListWrapper itemPerRow={[1, 1, 1]}>
								<TableWrapper>
									<Table
										fullHeight
										data={relatedContent}
										columns={columns}
										page={0}
										hideFooter
										rowClickable={true}
										onRowClick={rowClickHandler}
									/>
								</TableWrapper>
							</GridListWrapper>
						)}
					</Section>
					<Section fullWidth>
						<TextEditor
							placeholder={formState.description.placeholder}
							value={formState?.description?.value}
							onChange={updateState('description')}
							height='auto'
						/>
					</Section>
				</DuoWrapper>
			</PsuedoFullHeightWrapper>
			<FooterWrapper>
				{glossarySlug ? (
					<Button small xs type='primary' onClick={editGlossaryHandler} disabled={disabled}>
						{strings('edit')}
					</Button>
				) : (
					<Button small xs type='primary' onClick={addGlossaryHandler} disabled={disabled}>
						{strings('add')}
					</Button>
				)}
			</FooterWrapper>
		</Wrapper>
	)
}

export default AddGlossary
