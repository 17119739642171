import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, TOAST_TYPE, Banner, Calendar } from '@base'
import { strings } from '@constants'
import { addCommodityPriceListApi } from '@data'
import CommodityPrice from '@data/model/CommodityPrice'
import { showLoader, showToast } from '@data/state/action'
import CommodityPriceFrom from './CommodityPriceForm'
import { toggleDrawer } from '@data/state/action/root'
import { DateWrapper, Heading, SectionHeaderWapper, StepTopWrapper, HeadingWapper } from './style'

const initialFormState = {
	sku: {
		label: strings('sku'),
		name: 'sku',
		disabled: false,
		required: true,
		value: '',
		dirty: false,
	},
	grade: {
		label: strings('grade'),
		name: 'grade',
		disabled: false,
		required: true,
		value: '',
		dirty: false,
	},
	price_date: {
		label: 'Date',
		name: 'price_date',
		disabled: false,
		required: true,
		value: new Date(),
		dirty: false,
	},
	ex_delhi: {
		label: 'Delhi',
		name: 'ex_delhi',
		disabled: false,
		required: true,
		value: '',
		dirty: false,
	},
	ex_raipur: {
		label: 'Raipur',
		name: 'ex_raipur',
		disabled: false,
		required: true,
		value: '',
		dirty: false,
	},
	ex_mumbai: {
		label: 'Mumbai',
		name: 'ex_mumbai',
		disabled: false,
		required: true,
		value: '',
		dirty: false,
	},
	ex_bangalore: {
		label: 'Bangalore',
		name: 'ex_bangalore',
		disabled: false,
		required: true,
		value: '',
		dirty: false,
	},
	ex_hyderabad: {
		label: 'Hyderabad',
		name: 'ex_hyderabad',
		disabled: false,
		required: true,
		value: '',
		dirty: false,
	},
}

const commodities = [
	{ _sku: 'TMT-Primary', _grade: '12-32mm,Fe500D', category_id: 'ms_tmt_bars' },
    { _sku: 'TMT-Secondary', _grade: '12-32mm,Fe500', category_id: 'ms_tmt_bars' },
    { _sku: 'HRC', _grade: '2.5-8mm, E350 BR',category_id: 'hr_coils' },
    { _sku: 'HRC', _grade: '2.5-8mm/CTL, IS2062 Gr E250Br', disabled: ['mumbai', 'raipur'],category_id: 'hr_coils' },
    { _sku: 'CRC', _grade: '0.5mm, IS513 CR1', category_id: 'cr_coils' },
    { _sku: 'CRC', _grade: '0.9mm/CTL, IS513-CR1', disabled: ['mumbai', 'hyderabad', 'delhi', 'raipur'],category_id: 'cr_coils' },
    { _sku: 'Angle', _grade: '50x50x6, IS2062 E250 BR', disabled: ['bangalore', 'delhi'] ,category_id: 'ms_angles' },
    { _sku: 'Angle-Secondary', _grade: '90x90, IS2062/2011 E250 Gr A', disabled: ['mumbai', 'bangalore', 'delhi', 'hyderabad'],category_id: 'ms_angles' },
    { _sku: 'Angle-Secondary', _grade: '75x75, IS2062/2011 E250 Gr A', disabled: ['mumbai', 'bangalore', 'delhi', 'raipur'],category_id: 'ms_angles' },
    { _sku: 'Channel-Primary', _grade: '100*50, IS2062 E250 BR', disabled: ['delhi'] ,category_id: 'ms_channel' },
    { _sku: 'Channel-Secondary', _grade: '100*50, IS2062/2011 E250 Gr A' , category_id: 'ms_channel' },
    { _sku: 'Melting Scrap', _grade: ',HMS 80:20',category_id: 'mild_steel_scrap' },
    { _sku: 'INGOT', _grade: '3.5x4.5inch, IS2830', disabled: ['mumbai'] },
    { _sku: 'ERW PIPE', _grade: '2.2-6mm, IS1239 IS1161', category_id: 'erw_pipes' },
    { _sku: 'PPGI', _grade: '0.5mm/CTL, 90GSM', category_id: 'ppgi_coils_(_colour_coated_)' },
    { _sku: 'SS 304', _grade: '3-25mm,', category_id: 'ss_coils' },
    { _sku: 'SS 316', _grade: '3-25mm,', category_id: 'ss_coils' },
    { _sku: 'Al Ingots', _grade: ',99.70%', category_id: 'aluminium_ingots' },
    { _sku: 'GP Coil', _grade: '0.6mm/CTL, 120GSM IS277', disabled: ['raipur', 'bangalore', 'delhi', 'hyderabad'], category_id: 'gp_coils' },
    { _sku: 'GP Coil', _grade: '0.8mm/CTL, 120GSM IS277', disabled: ['raipur', 'bangalore', 'delhi', 'hyderabad'], category_id: 'gp_coils' },
    { _sku: 'Binding Wire-Secondary', _grade: '20Gauge,', disabled: ['mumbai', 'bangalore', 'delhi', 'hyderabad'], category_id: 'binding_wires' },
    { _sku: 'HB Wire', _grade: '12Gauge,', disabled: ['mumbai', 'bangalore', 'delhi', 'hyderabad'], category_id: 'binding_wires' },
    { _sku: 'Wire Rod', _grade: '5.5-6mm,IS7904 HC', disabled: ['mumbai', 'bangalore', 'delhi', 'raipur'] ,category_id: 'wire_rod' },
    { _sku: 'Wire Rod', _grade: '5.5mm,SWRY14 LC Gr3', disabled: ['mumbai', 'bangalore', 'delhi', 'hyderabad'] ,category_id: 'wire_rod' },
    { _sku: 'HR Plate', _grade: '5-10mm,Gr E250 Br', disabled: ['raipur', 'bangalore', 'hyderabad'] ,category_id: 'hr_sheets_|_plates' },
    { _sku: 'HR Plate', _grade: '20-40mm,IS2602 E250 Br', disabled: ['raipur', 'bangalore', 'hyderabad'] ,category_id: 'hr_sheets_|_plates' },
    { _sku: 'HR Plate', _grade: '60-100mm,IS2062 E250 Br', disabled: ['raipur', 'bangalore', 'hyderabad'] ,category_id: 'hr_sheets_|_plates' },
    // { _sku: 'Al Sows', _grade: ',99.70%' },category_id: 'aluminium_sow' },
    // { _sku: 'Al Rods', _grade: 'EC 0.5mm,' },category_id: 'aluminium_wire_rod' },
    // { _sku: 'Cu Cathode', _grade: ',Grade-A' ,category_id: 'copper_cathode' },
    { _sku: 'Zinc Ingots', _grade: ',SHG', category_id: 'zinc_ingots' },
    { _sku: 'Billet', _grade: '100x100mm, IS2831', disabled: ['mumbai', 'bangalore', 'delhi'] ,category_id: 'ms_billet' },
    { _sku: 'NPB', _grade: '400x180x66.30, IS2062 E350 BR', disabled: ['mumbai', 'bangalore', 'delhi', 'hyderabad'] ,category_id: 'narrow_parallel_beams' },
]

const AddCommodityPrice = ({ update }) => {
	const dispatch = useDispatch()
	const [initialState, setInitialState] = useState([])
	const [sourceValue, setSourceValue] = useState('steelmint')
	const loggedInUser = useSelector((state) => state.getIn(['user', 'user']))

	useEffect(() => {
		const _setForm = []
		for (const element of commodities) {
			const sku = Object.assign({}, initialFormState?.sku)
			const grade = Object.assign({}, initialFormState?.grade)
			sku['value'] = element._sku
			grade['value'] = element._grade
			_setForm.push({ ...initialFormState, sku, grade })
		}
		setInitialState(_setForm)
	}, [])

	const onChangeHandle = (index, name, value) => {
		setInitialState((state) => {
			return state.map((_item, _index) => {
				if (_index === index) {
					return { ..._item, [name]: { ..._item[name], value } }
				}
				return _item
			})
		})
	}

	const onChangeSourceHandler = (val) => {
		setSourceValue(val)
	}

	const productNamehandler = (name) => {
		return name.replace('-Secondary', '').replace('-Primary', '')
	}

	const checkType = (type) => {
		if (type.includes('Secondary')) return 'secondary'
		return 'primary'
	}

	const submitCommodityPriceHanlder = () => {
		const _initialState = initialState?.map((item, index) => {
			let _data = []
			if (item?.ex_delhi?.value) {
				_data.push(
					new CommodityPrice({
						location: item?.ex_delhi?.label,
						price_per_ton: item?.ex_delhi?.value,
						primary_or_secondary: checkType(commodities[index]._sku) || '',
						product_name: productNamehandler(commodities[index]._sku),
						source: sourceValue,
						grade: item?.grade?.value,
						price_date: new Date(item?.price_date?.value).getTime() || 0,
						category_id: commodities[index].category_id,
						created_by: loggedInUser?.user_id,
					})
				)
			}
			if (item?.ex_raipur?.value) {
				_data.push(
					new CommodityPrice({
						location: item?.ex_raipur?.label,
						price_per_ton: item?.ex_raipur?.value,
						primary_or_secondary: checkType(commodities[index]._sku) || '',
						product_name: productNamehandler(commodities[index]._sku),
						source: sourceValue,
						grade: item?.grade?.value,
						price_date: new Date(item?.price_date?.value).getTime() || 0,
						category_id: commodities[index].category_id,
						created_by: loggedInUser?.user_id,
					})
				)
			}
			if (item?.ex_mumbai?.value) {
				_data.push(
					new CommodityPrice({
						location: item?.ex_mumbai?.label,
						price_per_ton: item?.ex_mumbai?.value,
						primary_or_secondary: checkType(commodities[index]._sku) || '',
						product_name: productNamehandler(commodities[index]._sku),
						source: sourceValue,
						grade: item?.grade?.value,
						price_date: new Date(item?.price_date?.value).getTime() || 0,
						category_id: commodities[index].category_id,
						created_by: loggedInUser?.user_id,
					})
				)
			}
			if (item?.ex_bangalore?.value) {
				_data.push(
					new CommodityPrice({
						location: item?.ex_bangalore?.label,
						price_per_ton: item?.ex_bangalore?.value,
						primary_or_secondary: checkType(commodities[index]._sku) || '',
						product_name: productNamehandler(commodities[index]._sku),
						source: sourceValue,
						grade: item?.grade?.value,
						price_date: new Date(item?.price_date?.value).getTime() || 0,
						category_id: commodities[index].category_id,
						created_by: loggedInUser?.user_id,
					})
				)
			}
			if (item?.ex_hyderabad?.value) {
				_data.push(
					new CommodityPrice({
						location: item?.ex_hyderabad?.label,
						price_per_ton: item?.ex_hyderabad?.value,
						primary_or_secondary: checkType(commodities[index]._sku) || '',
						product_name: productNamehandler(commodities[index]._sku),
						source: sourceValue,
						grade: item?.grade?.value,
						category_id: commodities[index].category_id,
						price_date: new Date(item?.price_date?.value).getTime() || 0,
						created_by: loggedInUser?.user_id,
					})
				)
			}
			return _data
		})

		dispatch(showLoader(true))
		addCommodityPriceListApi(_initialState.flat(1))
			.then((res) => {
				dispatch(showToast(true, strings('commodity_prices_successfully'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
			})
			.catch((error) => {})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}

	const renderInventoryCTA = () => {
		return (
			<StepTopWrapper>
				<Banner extendInMobile text={strings('submit_the_price_ton')} />
			</StepTopWrapper>
		)
	}

	const checkDisabled = initialState.find((item) => item?.ex_delhi?.value || item?.ex_mumbai?.value || item?.ex_raipur?.value)

	return (
		<>
			<DrawerBodyWrapper>
				<SectionHeaderWapper>
					{renderInventoryCTA()}
					<DateWrapper>
						<Calendar
							disabled={false}
							small
							maxDate={new Date()}
							label={initialState[0]?.price_date?.label}
							value={initialState[0]?.price_date?.value}
							onChange={(val) => {
								commodities.map((item, index) => {
									onChangeHandle(index, initialState[0]?.price_date?.name, val)
								})
							}}
						/>
					</DateWrapper>
					<Input
						small
						label={strings('source')}
						type='text'
						value={sourceValue}
						name={'source'}
						onChange={(val) => {
							onChangeSourceHandler(val)
						}}
					/>
				</SectionHeaderWapper>
				<HeadingWapper>
					<Heading>{strings('sku')}</Heading>
					{/* <Heading>{strings('date')}</Heading> */}
					<Heading>{strings('grade')}</Heading>
					<Heading>{strings('ex_delhi_short')}</Heading>
					<Heading>{strings('ex_raipur_short')}</Heading>
					<Heading>{strings('ex_mumbai_short')}</Heading>
					<Heading>{strings('ex_bangalore_short')}</Heading>
					<Heading>{strings('ex_hyderabad_short')}</Heading>
				</HeadingWapper>
				{initialState?.map((item, index) => (
					<CommodityPriceFrom item={item} index={index} onChangeHandle={onChangeHandle} key={index} commodities={commodities} />
				))}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase type='primary' disabled={!checkDisabled} onClick={submitCommodityPriceHanlder}>
					{strings('submit')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddCommodityPrice
