class Commodity {
  constructor() {
    this.commodity_id = 'COtx2Px-1001'
    this.commodity_name = 'MILD STEEL'
    this.commodity_type = 'FERROUS'
    this.brands = null; //list
  }
}

export default Commodity;
