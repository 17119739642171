import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, MultiSelect, Select } from '@base'
import { useEffect, useMemo, useState } from 'react'
import { ADMIN_SUB_ROLES, CountryCodes, DRAWER_TYPES, enquiryPlatform, MB_ROLES, REGION_TYPES, strings, SUBROLE_TYPES } from '@constants'
import { BiFieldWrapper, EditDetailWrapper, GridListWrapper, Label, Value } from '../styles'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCommodityApi } from '@data'
import { isClient, isSuperAdmin } from 'src/utils/adminFilter'
import { Spacings } from '@styles'
import { toggleDrawer } from '@data/state/action/root'
import { getCountryDialCode } from '@utils'

const deriveStateFromProp = (user) => {
	const _state = {
		fullName: {
			key: 'full_name',
			value: user?.full_name || '',
			valid: true,
		},
		mobileNumber: {
			key: 'mobile_number',
			value: user?.mobile_number || '',
			valid: true,
			disabled: +user?.mobile_number !== 91,
		},
		emailId: {
			key: 'email_id',
			value: user?.email_id || '',
			valid: true,
		},
		commodity: {
			key: 'commodity',
			value: user?.commodity || [],
			valid: true,
		},
		role: {
			key: 'role',
			value: user?.role || [],
			valid: true,
		},
		region: {
			key: 'region',
			value: user?.region || '',
			valid: true,
		},
		monthly_target: {
			key: 'monthly_target',
			value: user?.monthly_target || 0,
			valid: true,
		},
		sub_role: {
			key: 'sub_role',
			value: user?.sub_role || 'PORTAL',
			valid: true,
		},
		country_code: {
			key: 'country_code',
			value: CountryCodes[0],
			valid: true,
		},
		platforms: {
			key: 'platforms',
			value: user?.platforms || [],
			valid: true,
		},
	}
	if (user) {
		_state.country_code.value = CountryCodes.find((item) => item.code === user?.country_code) || CountryCodes[0]
	}
	return _state
}

const EditUserDetails = ({ user, update, refreshUpdate, isAdminUser, getAllAdmins, updateReportingManager }) => {
	const loggedInuser = useSelector((_state) => _state.getIn(['user', 'user']))
	const dispatch = useDispatch()
	const userData = useSelector((_state) => _state.getIn(['root', 'drawerTypeMeta', 'user']))
	const [allCommodity, setAllCommodity] = useState([])
	const _getAllAdmins = getAllAdmins?.map((item) => {
		item['display_name'] = `${(item.full_name || '') + ' '}(${item.mobile_number})`
		return item
	})
	const [details, setDetails] = useState(deriveStateFromProp())
	const [selectedManager, setSelectedManager] = useState(_getAllAdmins?.find((item) => item?.user_id === user?.reporting_managers?.[0]) || {})

	useEffect(() => {
		const dialCode = getCountryDialCode(user.country_code)
		setDetails(deriveStateFromProp({ ...user, mobile_number: user?.mobile_number?.replace(dialCode, '')?.trim() }))
		getAllCommodityApi().then((res) => {
			setAllCommodity(res)
		})
	}, [user])

	const updateState = (type, key) => (value) => {
		if (typeof value === 'string') {
			value = value.trimStart()
		}
		if (type === 'role') {
			value = value.role.trimStart()
		}
		if (type === 'platforms') {
			value = value.map((item) => item.key)
		}
		setDetails((details) => ({
			...details,
			[type]: {
				...details[type],
				[key]: value,
			},
		}))
	}

	const disableButton = useMemo(() => {
		return Object.values(details).some((d) => !d.valid)
	}, [details])
	const updateDetails = () => {
		let updatedObject = Object.values(details).reduce(
			(acc, value) => ({
				...acc,
				[value.key]: value.value,
			}),
			{}
		)
		updatedObject = {
			...updatedObject,
			mobile_number: getCountryDialCode(details.country_code.value.code) + details.mobileNumber.value,
			country_code: details?.country_code.value.code,
		}
		if (!updatedObject?.commodity?.find((item) => item?.active)) {
			if (updatedObject.commodity[0]) updatedObject.commodity[0].active = true
		}
		update(updatedObject, userData)
	}

	const onChangeManager = (selectChangeUser) => {
		setSelectedManager(selectChangeUser)
		updateReportingManager(user, selectChangeUser)
	}

	const deleteAdminHandler = () => {
		dispatch(toggleDrawer(true, DRAWER_TYPES.DELETE_ADMIN, { label: strings('delete', 'Admin'), user, getAllAdmins, refreshUpdate }))
	}

	let subRoleTypes = Object.entries(SUBROLE_TYPES).map(([key, value]) => ({ key, sub_role: value }))
	let adminSubRoleTypes = Object.entries(ADMIN_SUB_ROLES).map(([key, value]) => ({ key, sub_role: value }))
	let businessCategoryTypes = Object.values(enquiryPlatform).map((i) => ({ key: i.name, label: i.name }))

	const renderAdminDetails = () => {
		let roles = Object.values(MB_ROLES)
			.map((i) => ({ role: i }))
			.sort((a, b) => {
				if (a.role < b.role) {
					return -1
				}
				if (a.role > b.role) {
					return 1
				}
				return 0
			})

		if (loggedInuser?.role === MB_ROLES.ADMIN) {
			roles = roles.filter((i) => i.role !== MB_ROLES.SUPER_ADMIN)
		}
		let regionTypes = Object.values(REGION_TYPES).map((i) => ({ region: i }))

		return (
			<>
				<EditDetailWrapper>
					<MultiSelect
						id='commodity_name'
						disabled={false}
						label={strings('commodity', 'name')}
						displayKey={'commodity_name'}
						primaryKey={'commodity_name'}
						placeholder={strings[('select', 'commodity', 'name')]}
						data={allCommodity}
						value={details.commodity?.value}
						onChange={updateState('commodity', 'value')}
						multiple
					/>
				</EditDetailWrapper>
				<GridListWrapper>
					<Select
						label={strings('region')}
						id='region'
						data={regionTypes}
						type='text'
						value={{ region: details.region.value }}
						onChange={(value) => updateState('region', 'value')(value.region)}
						placeholder={strings('region')}
						displayKey='region'
						primaryKey='region'
					/>
					<Select
						id='select-manager'
						label={strings('reporting', 'manager')}
						placeholder={strings(['select', 'reporting', 'manager'])}
						data={getAllAdmins}
						displayKey='display_name'
						primaryKey='user_id'
						value={selectedManager}
						disabled={loggedInuser?.user_id === user?.user_id || !isSuperAdmin(loggedInuser?.role)}
						onChange={onChangeManager}
					/>
				</GridListWrapper>

				<GridListWrapper itemPerRow={[2, 2, 2]}>
					<Select
						id='select-role'
						label={strings('role')}
						placeholder={strings('select', 'role')}
						data={roles}
						displayKey='role'
						primaryKey='role'
						value={{ role: details.role.value }}
						disabled={!isSuperAdmin(loggedInuser?.role)}
						onChange={updateState('role', 'value')}
					/>

					<Select
						label={strings('sub_role')}
						id='sub_role'
						data={adminSubRoleTypes}
						type='text'
						value={adminSubRoleTypes.find((item) => item.key === details?.sub_role?.value) ?? {}}
						onChange={(value) => updateState('sub_role', 'value')(value.key)}
						placeholder={strings(['select', 'sub_role'])}
						displayKey='sub_role'
						primaryKey='key'
					/>
				</GridListWrapper>
				<EditDetailWrapper>
					<Input
						id='monthly-target'
						type='number'
						placeholder={strings('0')}
						label={strings('monthly', 'target')}
						value={details?.monthly_target?.value}
						onChange={updateState('monthly_target', 'value')}
					/>
				</EditDetailWrapper>
				<EditDetailWrapper>
					<MultiSelect
						id='business-category'
						label={strings('business_category')}
						placeholder={strings('select', 'business_category')}
						value={details?.platforms?.value?.map((item) => ({ key: item, label: item })) ?? []}
						data={businessCategoryTypes}
						onChange={updateState('platforms', 'value')}
						primaryKey='key'
						displayKey='label'
					/>
				</EditDetailWrapper>
			</>
		)
	}

	return (
		<>
			<DrawerBodyWrapper>
				<EditDetailWrapper>
					<Input
						id='user-name'
						type='text'
						placeholder={strings('your_full_name')}
						label={strings('name')}
						value={details?.fullName?.value}
						onChange={updateState('fullName', 'value')}
					/>
				</EditDetailWrapper>
				<EditDetailWrapper>
					<Input
						type='email'
						pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
						label={strings('email')}
						placeholder={strings('your_email_address')}
						setValidity={updateState('emailId', 'valid')}
						errorMap={{
							patternMismatch: `${strings('enter_valid_email')}`,
						}}
						value={details?.emailId?.value}
						onChange={updateState('emailId', 'value')}
					/>
				</EditDetailWrapper>
				<BiFieldWrapper>
					<Select
						id={'country-code'}
						data={CountryCodes}
						displayKey='dial_code'
						primaryKey='name'
						value={CountryCodes.find((item) => details?.country_code?.value?.code === item.code) ?? CountryCodes[0]}
						onChange={(val) => updateState('country_code', 'value')(val)}
						type='tel'
						noDropIcon
					/>
					<Input
						id='user-mobile'
						label={strings('mobile')}
						type='tel'
						placeholder={strings('enter_your_mobile_num')}
						maxLength={12}
						minLength={!details?.mobileNumber?.disabled && 7}
						pattern='^\d{7}\d+$'
						value={details?.mobileNumber?.value}
						onChange={updateState('mobileNumber', 'value')}
						disabled={
							12 === details?.mobileNumber?.value?.length &&
							details?.mobileNumber.disabled &&
							!isSuperAdmin(loggedInuser?.role) &&
							userData?.mobile_number?.length === 12
						}
						errorMap={{
							patternMismatch: `${strings('enter_valid_num')}`,
							valueMissing: `${strings('mob_num_required')}`,
						}}
						setValidity={updateState('mobileNumber', 'valid')}
					/>
				</BiFieldWrapper>
				{isClient(user.role) && (
					<Select
						label={strings('sub_role')}
						id='sub_role'
						data={subRoleTypes}
						type='text'
						value={subRoleTypes.find((item) => item.key === details?.sub_role?.value) ?? {}}
						onChange={(value) => updateState('sub_role', 'value')(value.key)}
						placeholder={strings(['select', 'sub_role'])}
						displayKey='key'
						primaryKey='sub_role'
					/>
				)}

				{isAdminUser && renderAdminDetails()}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase type='primary' disabled={disableButton} onClick={updateDetails}>
					{strings('update')}
				</Button>
				{isAdminUser && (
					<Button
						small
						uppercase
						type='secondary'
						margin={`0 ${Spacings.SPACING_2B} 0 0`}
						variant='destructive'
						onClick={deleteAdminHandler}
					>
						{strings('delete', 'admin')}
					</Button>
				)}
			</DrawerFooterWrapper>
		</>
	)
}

export default EditUserDetails
