import API from '../api'
import deleteUrl from './paths'

export const deleteOrganisationUser = (loggedUserId, orgId, userId) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteUser, { loggedUserId, orgId, userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const deleteOrganisation = (organisationId) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteOrganisation, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
	})

export const deleteAddressV2 = (organisationId, addressId, addressType) =>
	new Promise((resolve, reject) => {
		API.delete(deleteUrl.deleteAddressV2, { organisationId, addressId, addressType })
			.then((response) => {
				resolve(response)
			})
			.catch((error) => {
				reject(error)
			})
	})
