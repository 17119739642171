const putUrl = {
	editUser: '/user',
	editProduct: '/product',
	editCategory: '/category',
	addCategoryImages: '/category/addCategoryImages',

	editEnquiry: '/enquiry',
	submitEnquiryQuote: '/enquiry/submitSupplierQuote',
	editEnquiryQuote: '/enquiry/editSupplierQuote',
	saveCostMetrics: '/enquiry/saveCostMetrics',

	submitBuyerQuote: '/enquiry/submitBuyerQuote',
	submitScrapSupplierQuote: '/enquiry/submitScrapSupplierQuote',

	editOrder: '/order',
	updateInvoice: '/order/updateInvoice',
	updateInvoiceV2: '/order/v2/updateInvoice',
	deleteInvoice: '/order/deleteInvoice',
	updatePaymentTermsAndTenure: '/order/updatePaymentTermsAndTenure',
	updateCostMetrics: '/order/updateCostMetrics',

	editAuction: '/auction',
	submitBuyerBid: '/auction/submitBuyerQuote',
	editBuyerBid: '/auction/editBuyerQuote',
	submitSupplierBid: '/auction/submitSupplierQuote',
	updateLogistics: '/logistics/updateLogisticsRequests',
	submitLogisticsQuote: '/logistics/submitLogisticsQuote',
	editLogisticsQuote: '/logistics/editLogisticsQuote',
	editLogisticsOrder: '/logistics/editLogisticsOrder',
	deleteLogisticsInvoice: '/logistics/deleteInvoice',

	updateMachine: '/machine',
	updateFactory: '/factory',

	updateFabricationOrder: '/factory/updateFabricationOrder',
	updateFabricationInvoice: '/factory/updateFabricationInvoice',
	updateFabricationRemainingInventory: '/factory/updateFabricationRemainingInventory',
	updateLogisticsPrices: '/logisticsPrices',
	updateInvoices: '/invoice',
	addOtherInvoiceDocuments: '/invoice/addOtherInvoiceDocuments',

	// updateUserMapping: '/user/updateUserMapping',

	updateInvoiceTracker: '/order/trip',
	kycStatus: '/kyc/kycStatus',
	updateLocation: '/kyc/updateLocation',
	mergeUsers: '/user/mergeUsers',
	changeUserRole: '/user/changeUserRole',
	updateReportingManager: '/user/updateReportingManager',
	editBanner: '/banner/edit',
	editContent: '/content/editContent',

	addAddressToOrganisation: '/organisation/addAddressToOrganisation',
	addGstToOrganisation: '/organisation/addGstToOrganisation',
	changeOrgName: '/organisation/changeName',
	changeOrgPan: '/organisation/updatePan',
	updateDefaultAdmin: '/organisation/updateDefaultAdmin',
	requestKycUpdate: '/organisation/requestKycUpdate',
	acceptRoles: '/organisation/acceptRoles',
	requestRoles: '/organisation/requestRoles',
	removeRoleFromOrganisation: 'organisation/removeRoleFromOrganisation',
	mergeOrganisation: '/organisation/mergeOrganisations',
	updateSharePrices: '/organisation/updateSharePrices',

	editCommodity: '/commodity',
	uploadBuyerExportDocuments: '/order/uploadExportDocuments',

	addMessageToEnquiryJourney: '/journey/addMessageToEnquiry',
	fetchCompanyData: 'organisation/fetchCompanyData',
	updateIsTrader: 'organisation/updateIsTrader',
	updateRegion: '/organisation/updateRegion',
	updateAccountDetails: '/organisation/updateAccountDetails',
	updateOrganisation: '/organisation/updateOrganisation',

	editPaymentReceivables: '/payment-receivables/editRemarks',
	addremarkToPaymentReceivables: '/payment-receivables/addRemarks',
	changeStatusPaymentReceivables: '/payment-receivables/updateStatus',

	updateLead: '/lead/',
	updateLeadStatus: '/lead/updateTaskStatus',
	addLeadTask: '/lead/addTask',
	updateTarget: '/user/updateTarget',
	editFinancials: 'financials',
	UpdateFinancialsBusinessDetail: 'financials/updateBusinessDetail',
	editPdc: '/financials/editPdc',
	setInsuredLimit: '/financials/setInsuredLimit',
	addFinancialsRemark: 'financials/addRemark',
	setFinancialsFeedback: 'financials/setFeedback',
	dataCorrection: 'ledger/dataCorrection',

	editPunchOrder: '/punchOrder',

	editGlossary: '/glossary',

	updateCredditLimit: '/creditLimit',
}

export default putUrl
