import {
	AccountBalanceWallet as AccountBalanceWalletIcon,
	Assignment as AssignmentIcon,
	Home as HomeIcon,
	LocalOffer as LocalOfferIcon,
	LocalShipping as LocalShippingIcon,
	Face as FaceIcon,
	Storefront as StorefrontIcon,
	ShoppingCart,
	Settings,
	Build,
	ListAlt,
	Storefront,
	Assessment as AssessmentIcon,
	HomeWork as HomeWorkIcon,
	LocalAtm as LocalAtmIcon,
	Receipt as ReceiptIcon,
	BusinessCenter as BusinessCenterIcon,
	Description as DescriptionIcon,
	Book as BookIcon,
} from '@material-ui/icons'
import { COLLECTION_STATUS, MB_ROLES, strings } from '@constants'
import paths from '../paths'

const collectionStatus = Object.keys(COLLECTION_STATUS)

const adminMenu = (orgRole) => {
	const sideMenuItems = [{ title: strings('dashboard'), icon: <HomeIcon />, href: paths.admin }]

	if (![MB_ROLES.TOOLS, MB_ROLES.FINANCE].includes(orgRole)) {
		sideMenuItems.push(
			{
				title: strings('enquiry'),
				submenu: [
					{ title: strings('create_enquiry'), href: paths.admin_create_enquiry, icon: <ShoppingCart /> },
					// { title: strings('punch_order'), href: paths.admin_punch_order, icon: <ShoppingCart /> },
					{ title: strings('manage_enquiries'), href: paths.admin_manage_enquiry, icon: <ListAlt /> },
					{ title: strings('manage_enquiry_orders'), href: paths.admin_manage_enquiry_orders, icon: <Settings /> },
				],
			},
			{
				title: strings('auction'),
				submenu: [
					{
						title: strings('create_auction'),
						href: paths.admin_create_auction,
						icon: <LocalOfferIcon />,
					},
					{
						title: strings('manage_auctions'),
						href: paths.admin_manage_auction,
						icon: <Storefront />,
					},
					{
						title: strings('manage_auction_orders'),
						href: paths.admin_manage_auction_orders,
						icon: <Settings />,
					},
				],
			},
			{ title: strings('orgs_and_users'), icon: <FaceIcon />, href: paths.admin_orgs_and_users }
		)
	}
	if ([MB_ROLES.FINANCE].includes(orgRole)) {
		sideMenuItems.push({ title: strings('organisation'), icon: <FaceIcon />, href: paths.admin_orgs_and_users })
	}
	if ([MB_ROLES.ADMIN, MB_ROLES.SUPER_ADMIN, MB_ROLES.FINANCE].includes(orgRole)) {
		sideMenuItems.push(
			{ title: strings('ledgers'), href: paths.admin_ledger, icon: <AccountBalanceWalletIcon /> },
			{ title: strings('underwriting'), icon: <BusinessCenterIcon />, href: paths.admin_underwriting }
		)
	}
	if ([MB_ROLES.ADMIN, MB_ROLES.SUPER_ADMIN].includes(orgRole)) {
		sideMenuItems.push({ title: strings('bills'), icon: <ReceiptIcon />, href: paths.admin_bills })
	}

	if ([MB_ROLES.ADMIN, MB_ROLES.SUPER_ADMIN].includes(orgRole)) {
		sideMenuItems.push(
			{ title: strings('cloud_factory'), icon: <HomeWorkIcon />, href: paths.admin_cloud_factory },

			{ title: strings('catalog'), icon: <StorefrontIcon />, href: paths.admin_catalog },
			{ title: strings('content'), icon: <BookIcon />, href: paths.admin_content },
			{ title: strings('logistics'), icon: <LocalShippingIcon />, href: paths.admin_logistics_all }
		)
	}

	if (![MB_ROLES.TOOLS, MB_ROLES.FINANCE].includes(orgRole)) {
		sideMenuItems.push(
			{ title: strings('commodity', 'prices'), icon: <LocalAtmIcon />, href: paths.admin_commodity_prices },
			{ title: strings('supplier_by_inventory'), icon: <AssignmentIcon />, href: paths.admin_list_inventory }
		)
	}

	if ([MB_ROLES.SUPER_ADMIN].includes(orgRole)) {
		sideMenuItems.push({ title: strings('reports'), icon: <AssessmentIcon />, href: paths.admin_reports })
	}

	if ([MB_ROLES.SALES].includes(orgRole)) {
		sideMenuItems.push({ title: strings('ledgers'), href: paths.admin_ledger, icon: <AccountBalanceWalletIcon /> })
	}
	if ([MB_ROLES.SUPPLY].includes(orgRole)) {
		sideMenuItems.push({ title: strings('ledgers'), href: paths.admin_ledger, icon: <AccountBalanceWalletIcon /> })
	}
	if (![MB_ROLES.FINANCE].includes(orgRole)) {
		sideMenuItems.push({ title: strings('tools'), icon: <Build />, href: paths.admin_tools })
	}
	sideMenuItems.push({ title: strings('mb_documents'), icon: <DescriptionIcon />, href: paths.mbDocuments })

	return sideMenuItems
}

export default adminMenu
